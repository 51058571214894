import React from "react"
import Layout from "../components/Layout"
import SingleHeader from "../components/SingleHeader"
import WorkFlow from "../components/homeComponents/WorkFlow"
import AboutUs from "../components/homeComponents/AboutUs"
import FunFact from "../components/aboutComponents/FunFact"
import LearnerFeedback from "../components/homeComponents/LearnerFeedback"
import { Link } from "gatsby"

export default function about() {
  return (
    <Layout>
      <SingleHeader pageTitle="About Us" pageName="About" />
      <WorkFlow />
      <AboutUs />
      <FunFact />
      <LearnerFeedback />
      <section class="cta-section">
        {/* <!-- Container --> */}
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="cta_wrap">
                <div class="cta_text">
                  <h3> Become An Instructor </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore facts.
                  </p>
                </div>
                <Link to="/contact" class="more-link">
                  Join Us Now
                </Link>
              </div>
            </div>
            {/* <!--/ col-md-12  --> */}
          </div>
          {/* <!--/ row - --> */}
        </div>
        {/* <!--/ Container - --> */}
      </section>
    </Layout>
  )
}
