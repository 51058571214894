import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "../../styles/aboutComponentStyles/funfact.css"

const FunFact = () => {
  return (
    <section class="funfacts-section">
      {/* <!-- Container --> */}
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-3">
            <div class="funfacts_item">
              <StaticImage
                src="../../images/funfact1.png"
                alt="funfacticon"
                placeholder="blurred"
                layout="constrained"
              />
              <h2> 2500+ </h2>
              <span> Online Courses </span>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3">
            <div class="funfacts_item">
              <StaticImage
                src="../../images/funfact2.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
              <h2> 7000+ </h2>
              <span> Students Enrolled </span>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3">
            <div class="funfacts_item">
              <StaticImage
                src="../../images/funfact3.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
              <h2> 340+ </h2>
              <span> Expert Instructors </span>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3">
            <div class="funfacts_item">
              <StaticImage
                src="../../images/funfact4.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
              <h2>9000+</h2>
              <span> Hours Content</span>
            </div>
          </div>
        </div>
        {/* <!--/ row - --> */}
      </div>
      {/* <!--/ Container - --> */}
    </section>
  )
}

export default FunFact
